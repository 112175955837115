<template> 
    <v-layout justify-center class="ma-0" fill-height  > 
        <mbs-page-spinner v-if="!Invoice" />  
        <v-card outlined v-else id="print_template" class="ma-1" width="300">
            <v-card v-if="Invoice.company" class="pt-5 one-line ma-5" flat >
                <div >
                    <div class="text-center font-weight-bold not-f5">{{MBS.actions.TEXT_UP(Invoice.company_name)}}</div>  
                    <div class="text-center font-weight-bold not-f3">Phone: {{Invoice.company.company_phone_number}}</div>  
                    <div class="text-center not-f3">Email: {{Invoice.company.company_email_address}}</div>  
                    <div class="text-center not-f3">Location Address: {{Invoice.company.company_location_address}}</div>  
                     
                </div>
                <div class="text-center not-f3 mt-2">TPIN: {{Invoice.company.company_tpin}}</div> 
            </v-card>

            <v-card flat outlined color="black mx-3 mb-3">
                <v-card flat class="text-center font-weight-bold">
                    <div>PAYMENT RECEIPT</div>
                </v-card>
            </v-card> 

            <div class="mx-4 mt-1">
                <v-layout class="ma-0">
                    <div>Invoice No: </div>
                    <v-spacer class="mbs-dashed-lin mx-1 mt-3"></v-spacer>
                    <div>{{Invoice.key}}</div>
                </v-layout>
                <v-layout class="ma-0">
                    <div>Date: </div>
                    <v-spacer class="mbs-dashed-lin mx-1 mt-3"></v-spacer>
                    <div>{{Invoice.created_at_}}</div>
                </v-layout>
                <v-layout class="ma-0">
                    <div>Bill To: </div>
                    <v-spacer class="mbs-dashed-lin mx-1 mt-3"></v-spacer>
                    <div>{{Invoice.customer_name}}</div>
                </v-layout>  
                <v-layout class="ma-0">
                    <div>Account: </div>
                    <v-spacer class="mbs-dashed-lin mx-1 mt-3"></v-spacer>
                    <div>{{Invoice.receivable_name}}</div>
                </v-layout>     
            </div>
            <v-list color="transparent" class="py-0"> 
                <!-- <v-divider class="mx-3 mt-5" ></v-divider>   -->
                <v-list-item class="font-weight-bold mt-2">
                    <v-list-item-content>REVENUE</v-list-item-content>
                    <v-list-item-action>TOTAL (MWK)</v-list-item-action>
                </v-list-item>
                <hr class="mx-3 mb-2"> 
                <div class="mx-4">
                    <v-layout class="ma-0">
                        <div>Invoice Amount: </div>
                        <v-spacer class="mbs-dashed-line mx-1 mt-3"></v-spacer>
                        <div>MWK {{MBS.actions.money(TotalPrice)}}</div>
                    </v-layout> 
                    <v-layout class="ma-0">
                        <div>Deposit: </div>
                        <v-spacer class="mbs-dashed-line mx-1 mt-3"></v-spacer>
                        <div>MWK {{MBS.actions.money(TotalPayments)}}</div>
                    </v-layout> 
                    <v-layout class="ma-0">
                        <div class="font-weight-bold not-f4">Balance: </div>
                        <v-spacer class="mbs-dashed-line mx-1 mt-3"></v-spacer>
                        <div class="font-weight-bold not-f3 mt-1 mr-2">MWK </div>
                        <div class="font-weight-bold not-f4">{{MBS.actions.money(TotalPrice+toNumber(Invoice?Invoice.amount:null)-TotalPayments)}}</div>
                    </v-layout> 
                </div>
                <v-divider class="mx-3 mt-5" ></v-divider>  
                <v-list-item  dense   >
                    <v-list-item-content >
                        <div>Sub-total</div>
                        <div>Tax</div> 
                    </v-list-item-content>
                    <v-list-item-action>
                        <div>MWK {{MBS.actions.money(Invoice?Invoice.amount:0)}}</div>
                        <div>MWK 0.00</div>
                    </v-list-item-action>   
                </v-list-item>
                
                <v-divider class="mx-3"></v-divider>
                <v-list-item     class="" >
                    <v-list-item-content>
                        <v-layout class="ma-0 one-line " align-center>
                            <div class="font-weight-bold not-f5">Total Paid</div> 
                        </v-layout>
                    </v-list-item-content>
                    <v-list-item-action>
                        <v-layout class="ma-0 one-line" align-center>
                            <div class="font-weight-bold not-f3 mt-1 mr-2">MWK </div> 
                            <div class="font-weight-bold not-f5">{{MBS.actions.money(Payment?Payment.amount:0)}}</div>
                        </v-layout> 
                    </v-list-item-action>   
                </v-list-item>
                <v-divider class="mx-3"></v-divider>
                <v-list-item  dense   >
                    <v-list-item-content >
                        <div>Cash:</div>
                        <div>Change:</div> 
                    </v-list-item-content>
                    <v-list-item-action>
                        <div>MWK {{MBS.actions.money(Payment.given_amount)}}</div>
                        <div>MWK {{MBS.actions.money(Payment.given_amount-Payment.paid_amount)}}</div>
                    </v-list-item-action>   
                </v-list-item>
            </v-list>    
            <!-- <v-layout justify-center>
                <mbs-barcode 
                tag="svg"
                :value="Invoice.key"
                :options="{height:50,width:1.8,margin:10}"/>  
            </v-layout>   -->
            <hr class="mx-3 mb-3">
            <v-layout justify-center align-center>
                <div>Outlet:</div> {{Invoice.outlet_name}}
            </v-layout>
            <v-layout justify-center align-center>
                <div>Teller:</div>{{Invoice.teller_name}}
            </v-layout> 
            <v-layout justify-center align-center>
                <div>till:</div>{{Invoice.till_name}}
            </v-layout>
            <v-divider class="mx-3 mt-3"></v-divider> 
            <v-layout column justify-center align-center class="one-line" >
                <div class="font-weight-bold not-f3">{{MBS.actions.TEXT_UP(DATA.APP.POWERED_BY)}}</div>
                <div class="font-weight-bold not-f3">{{DATA.APP.ADMIN_PHONE}}</div> 
            </v-layout>
            <v-divider class="mx-3"></v-divider> 
            <br><br>
        </v-card>
    </v-layout> 
</template>

<script>
    import DATA from "../../../plugins/DATA"
    import {mapState,mapGetters } from "vuex"
    let NAME = 'DIALOG'
    export default {
        props:['data','type'], 
        data(){
            return{
                show:true,
                DATA:DATA,
                printSVG:null,
                inputLoading:true
            } 
        }, 
        mounted(){ 
        },
        computed:{
            ...mapState({
                loading:state=>state.load.loading,
                processes:state=>state.load.processes,
                responses:state=>state.load.responses,
            }),
            ...mapState({
                us: state=> state.users[(DATA.ITEMS.USERS.value).toUpperCase()],
                ud: state=> state.users[(DATA.ITEMS.USER_DETAILS.values).toUpperCase()],
            }),
            ...mapState({
                Items: state=> state.items[(DATA.ITEMS.ITEMS.values).toUpperCase()],
                JoinedItems: state=> state.join[(DATA.ITEMS.ITEMS.values).toUpperCase()], 
                Payments: state=> state.items[(DATA.ITEMS.PAYMENTS.values).toUpperCase()],
                JoinedPayments: state=> state.join[(DATA.ITEMS.PAYMENTS.values).toUpperCase()],  
                SaleOrders: state=> state.items[(DATA.ITEMS.SALE_ORDERS.values).toUpperCase()],
                JoinedSaleOrders: state=> state.join[(DATA.ITEMS.SALE_ORDERS.values).toUpperCase()],  
            }), 
            DataPayments(){
                let Payments = this.Payments
                let JoinedPayments = this.JoinedPayments 
                return JoinedPayments?JoinedPayments:Payments
            },
            DataSaleOrders(){
                let SaleOrders = this.SaleOrders
                let JoinedSaleOrders = this.JoinedSaleOrders 
                return JoinedSaleOrders?JoinedSaleOrders:SaleOrders
            },
            vs(){
                return this.$vuetify.breakpoint
            }, 
            vsh(){
                return this.vs.height
            },
            Payment(){ 
                let data = this.data 
                if(!data){return null}
                if (data.reload) {
                    let payments = this.DataPayments
                    if(!payments){return null}
                    let thisPayment = payments.find(payment=>{
                        return payment.key == data.key
                    })
                    return thisPayment
                }
                return  data
            },
            Invoice(){ 
                let orders = this.DataSaleOrders 
                let payment = this.Payment 
                let transaction_key = payment?payment.transaction_key:null 
                if(!orders || !transaction_key){return null}
                let order = orders.find(order=>{
                    return order.key == transaction_key
                }) 
                return order
            }, 
            InvoicePayments(){
                let payments = this.DataPayments
                let Invoice = this.Invoice
                if(!payments||!Invoice){return null}
                let filled_items = payments.filter(item=>{
                    return item.transaction_key == Invoice.key
                })
                return filled_items
            },
            TotalPrice(){
                try { 
                    let items = this.Invoice?this.Invoice.joined_items:null
                    let total_price = 0 
                    if (!items) {return 0}
                    items.forEach(item => {
                        let sold_price = this.toNumber(item.sold_price)
                        let discount = this.toNumber(item.discount)
                        let quantity = this.toNumber(item.quantity)
                        total_price = total_price + (quantity*(sold_price-discount))
                    });  
                    return total_price
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'TotalPrice',
                        page:PAGE_NAME, 
                    }) 
                    return 0
                }  
            }, 
            TotalPayments(){
                try { 
                    let items = this.InvoicePayments
                    let total_amount = 0 
                    if (!items) {return 0}
                    items.forEach(item => {
                        let amount = this.toNumber(item.amount) 
                        total_amount = total_amount + amount
                    });  
                    return total_amount
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'TotalPayments',
                        page:PAGE_NAME, 
                    }) 
                    return 0
                }  
            },  
        },
        methods:{
            CALCULATE_PRICE(item){
                try { 
                    if (!item) {return 0}
                    let sold_price = this.toNumber(item.sold_price)
                    let discount = this.toNumber(item.discount)
                    let quantity = this.toNumber(item.quantity)
                    return quantity*(sold_price-discount)
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'CALCULATE_PRICE',
                        page:PAGE_NAME, 
                    }) 
                }
                
            },   
        },
        watch:{
            mbs_dialog(value){ 
            },   
        } 
    }
</script>
<style scoped>
    .full-width{
        width: 100% !important;
    }
    .full-height{
        height: 100% !important;
    }
    .one-line{
        white-space: nowrap;
    }
</style>
